import React, { useState } from 'react';
import { Button, message } from 'antd';
import s from './s.module.less';
import { sendVerify } from 'api/public';
import MessageSentImg from 'assets/signup/Message-Sent.svg';
import Icon from 'assets/logo/logo-icon.svg';
import { Email } from 'constants/contact';
import EmailImg from 'assets/common/email.svg';

type Props = {
    email: string;
};

export default function VerifyEmail(props: Props) {
    const { email } = props;
    const [resentLoading, setResentLoading] = useState(false);

    const onResent = async () => {
        setResentLoading(true);
        const result = await sendVerify(email);
        if (!result.error) {
            message.success('Resend email verification success');
        }
        setResentLoading(false);
    };

    return (
        <div className={s.wrap}>
            <div className={s.top}>
                <img src={Icon} />
            </div>
            <div className={s.imgWrap}>
                <img src={MessageSentImg} alt="" />
            </div>
            <div className={s.content}>
                <h2>
                    Verify your email
                </h2>
                <p className={s.tips}>
                    An activation link has been sent to <span>{email}</span>. If you haven’t received it after a few minutes, please check your spam folder.
                </p>
                <Button
                    size='large'
                    loading={resentLoading} block type="primary" onClick={onResent}>Resent a verification email</Button>
                <p className={s.tips2}>Need help? Speak to our friendly team.</p>
                <div className={s.contact}>
                    <img className="w-4 h-4 mr-2" src={EmailImg} alt="" />
                    <a href={`mailto:${Email.support}`}>{Email.support}</a>
                </div>
            </div>
        </div>
    );
}
