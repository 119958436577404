import React, { useState } from 'react';
import { Button, message } from 'antd';
import s from './s.module.less';
import { sendVerify } from 'api/public';
import WelcomeImg from './welcome.svg';
import Icon from 'assets/logo/logo-icon.svg';
import { Email } from 'constants/contact';
import EmailImg from 'assets/common/email.svg';



export default function MobileWelcomePage() {
    return (
        <div className={s.wrap}>
            <div className={s.top}>
                <img src={Icon} />
            </div>
            <div className={s.imgWrap}>
                <img src={WelcomeImg} alt="" />
            </div>
            <div className={s.content}>
                <h2>
                    Welcome to Kiwi Health!
                </h2>
                <p className={s.tips}>
                    You're all set. For a more streamlined application process, please open the link using a desktop or laptop browser. this will ensure a better experience.
                </p>
                <p className={s.linkWrap}>
                    <a className={s.link} href="https://app.kiwihealth.com" target="_blank" rel="noreferrer">https://app.kiwihealth.com</a>
                </p>
            </div>
        </div>
    );
}
